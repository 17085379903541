import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'

import Index from 'components/index-container'

const RuIndex = ({ data }) => <Index data={data} cult='ru' />

RuIndex.propTypes = { data: PropTypes.any }

export const query = graphql`
  {
    markdownRemark(frontmatter: { path: { eq: "/ru" } }) {
      htmlAst
      html
      frontmatter {
        title
        path
        lang
      }
    }
  }
`

export default RuIndex
